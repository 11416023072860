/**
 * GRAPH of CRUD for clients
 */

import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import { omit } from '@/shared/utils';

/**
 * To recover customers
 */
export const getClients = async () => {
	try {
		const response = await apollo.query({
			query: gql`
				query GET_CLIENTS {
					GetClients {
						id
						licenseId
						iban
						bic
						info
						contactName
						contactFunction
						nationalRegistryNumber
						vat
						phone
						email
						name
						type
						addressId
						companyType
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
						locations {
							id
							projectId
							clientId
							setName
							type
							selected
							description
							note
							setAddressId
							setAddress {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
							images
							documents
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});
		const { GetClients } = response.data;
		return {
			clients: GetClients
		};
	} catch (error) {
		console.log(error);
	}
};

/**
 * To retrieve a client
 * @param {Number} clientId
 */
export const getClient = async (clientId) => {
	try {
		const response = await apollo.query({
			query: gql`
				query GET_CLIENT($clientId: ID!) {
					GetClient(ClientId: $clientId) {
						id
						licenseId
						iban
						bic
						info
						contactName
						contactFunction
						nationalRegistryNumber
						vat
						phone
						email
						name
						type
						addressId
						companyType
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
						locations {
							id
							projectId
							clientId
							setName
							type
							selected
							description
							note
							setAddressId
							setAddress {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
							images
							documents
						}
					}
				}
			`,
			variables: {
				clientId: parseInt(clientId)
			},
			fetchPolicy: 'no-cache'
		});
		const { GetClient } = response.data;
		return {
			id: parseInt(clientId),
			...GetClient
		};
	} catch (error) {
		console.log(error);
	}
};

/**
 * Find a customer
 * @param clientName
 * @returns {Promise<*>}
 * @constructor
 */
export const addClient = async (clientName) => {
	try {
		const response = await apollo.mutate({
			mutation: gql`
				mutation ADD_CLIENT($clientName: String!) {
					AddClient(ClientName: $clientName) {
						id
						name
						type
					}
				}
			`,
			variables: {
				clientName
			},
			fetchPolicy: 'no-cache'
		});
		const new_address = await createNewAddress();

		const { AddClient } = response.data;
		await updateClient({
			id: AddClient.id,
			client: { addressId: parseInt(new_address.id) },
			address: omit(['id'], new_address)
		});
		return AddClient;
	} catch (error) {
		console.log(error);
	}
};

/**
 * Update a Customer
 * @returns {Promise<{client}>}
 * @constructor
 */
export const updateClient = async ({ id, client, address }) => {
	try {
		const response = await apollo.mutate({
			mutation: gql`
				mutation UPDATE_CLIENT($updatedClient: ClientInput!, $updatedClientAddress: AddressInput!, $clientId: ID!) {
					UpdClient(UpdatedClient: $updatedClient, UpdatedClientAddress: $updatedClientAddress, ClientId: $clientId) {
						id
						licenseId
						iban
						bic
						info
						contactName
						contactFunction
						nationalRegistryNumber
						vat
						phone
						email
						name
						type
						addressId
						companyType
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
						locations {
							id
							projectId
							clientId
							setName
							type
							selected
							description
							note
							setAddressId
							setAddress {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
							images
							documents
						}
					}
				}
			`,
			variables: {
				updatedClient: client,
				updatedClientAddress: address,
				clientId: parseInt(id, 10)
			},
			fetchPolicy: 'no-cache'
		});

		const { UpdClient } = response.data;
		return UpdClient;
	} catch (error) {
		console.log(error);
	}
};

/**
 * Delete a Customer
 * @param clientId
 * @param addressId
 * @returns {Promise<void>}
 * @constructor
 */
export const deleteClient = async (clientId, addressId) => {
	try {
		await apollo.mutate({
			mutation: gql`
				mutation DELETE_CLIENT($clientId: ID!) {
					DelClient(ClientId: $clientId)
				}
			`,
			variables: {
				clientId: parseInt(clientId)
			},
			fetchPolicy: 'no-cache'
		});

		await deleteAdresse(addressId, 10);
	} catch (error) {
		console.log(error);
	}
};

const createNewAddress = async () => {
	const response = await apollo.mutate({
		mutation: gql`
			mutation NewAddress {
				NewAddress {
					id
					projectId
					street
					number
					box
					zip
					state
					city
					country
					countryCode
					countryKey
					other
					lat
					lng
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});
	const { NewAddress } = response.data;
	return NewAddress;
};

const deleteAdresse = async (addressId) => {
	await apollo.mutate({
		mutation: gql`
			mutation DelAddress($addressId: ID!) {
				DelAddress(AddressId: $addressId)
			}
		`,
		variables: {
			addressId: parseInt(addressId, 10)
		},
		fetchPolicy: 'no-cache'
	});
};
